





































































































































































































import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import LandingSection from '@/components/LandingSection.vue'
import { useLandingPage } from '@/composition/useLandingPage'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'PrivacyNotice',

  components: {
    SiteHeader,
    SiteFooter,
    LandingSection,
  },

  setup() {
    const { page } = useLandingPage({
      name: 'Home',
      sections: [
        {
          id: 'hero',
          name: 'Hero',
        },
      ],
    })

    return {
      page,
    }
  },
})
