

































































































































































































































import BrandLogo from '@/components/branding/BrandLogo.vue'
import { LandingPageHeaderSection } from '@/composition/useLandingPage'
import { useScrollPosition } from '@/composition/useScrollPosition'
import { useColorScheme } from '@/composition/useColorScheme'
import { currentRoute } from '@/router'
import appConfig from '@/app.config.json'
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from '@vue/composition-api'

export default defineComponent({
  name: 'SiteHeader',

  components: {
    BrandLogo,
  },

  props: {
    isDefault: {
      type: Boolean,
      default: true,
    },
    sections: {
      type: Array as PropType<LandingPageHeaderSection[]>,
      required: true,
    },
  },

  setup() {
    const isMobileMenuOpen = ref(false)

    watch(
      () => currentRoute.value,
      () => {
        isMobileMenuOpen.value = false
      }
    )

    const { position } = useScrollPosition()
    const isScrolled = computed(() => position.y > 0)

    function closeMenu(): void {
      if (!isMobileMenuOpen.value) return
      isMobileMenuOpen.value = false
    }

    function toggleMenu(): void {
      isMobileMenuOpen.value = !isMobileMenuOpen.value
    }

    const { toggleDarkMode, isInDarkMode } = useColorScheme()

    const { facebook, instagram } = appConfig.socials

    const socials = {
      facebook,
      instagram,
    }

    return {
      title: appConfig.title,
      isMobileMenuOpen,
      isScrolled,
      closeMenu,
      toggleMenu,
      isInDarkMode,
      toggleDarkMode,
      socials,
    }
  },
})
