

































































import BrandLogoMark from '@/components/branding/BrandLogoMark.vue'
import { computed, defineComponent } from '@vue/composition-api'
import { socials } from '@/app.config.json'

export default defineComponent({
  name: 'SiteHeader',

  components: {
    BrandLogoMark,
  },

  setup() {
    const currentYear = computed(() => new Date().getUTCFullYear())

    return {
      currentYear,
      socials,
    }
  },
})
