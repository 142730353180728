import { Location } from 'vue-router'
import { computed } from '@vue/composition-api'
import { AsyncComponent, Component } from 'vue'
import { capitalizeFirstLetter, decapitalizeFirstLetter } from '@/utils/misc'
export interface LandingPageConfig {
  name: string
  sections: LandingPageSectionConfig[]
  i18nBasePath?: string
}

export interface LandingPageSectionConfig {
  id: string
  name: string
  toRoute?: Location
  isDisplayedInHeader?: boolean
}

export interface LandingPageHeaderSection {
  name: string
  href?: string
  toRoute?: Location
  i18nPath: string
}

export interface LandingPageSection {
  id: string
  component: Component<any, any, any, any> | AsyncComponent<any, any, any, any>
}

export function useLandingPage({
  name,
  sections: landingPageSections,
  i18nBasePath = 'landingSections',
}: LandingPageConfig) {
  const computedSections = computed(() => {
    const headerSections: LandingPageHeaderSection[] = []
    const contentSections: LandingPageSection[] = []

    for (const {
      id: sectionId,
      name: sectionName,
      toRoute,
      isDisplayedInHeader,
    } of landingPageSections) {
      const normalizedSectionName = `${capitalizeFirstLetter(sectionName)}`

      if (isDisplayedInHeader) {
        headerSections.push({
          name: sectionName,
          ...(toRoute
            ? {
                toRoute: { name, ...toRoute },
              }
            : {
                href: `#${sectionId}`,
              }),
          i18nPath: `${i18nBasePath}.${decapitalizeFirstLetter(sectionName)}`,
        })
      }

      contentSections.push({
        id: sectionId,
        component: () =>
          import(
            /* webpackChunkName: '[request]' */
            `@/components/LandingSection${normalizedSectionName}.vue`
          ),
      })
    }

    return {
      headerSections,
      contentSections,
    }
  })

  return {
    page: computedSections,
  }
}
